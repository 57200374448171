<template>
  <div>
    <ManageForm9 />
  </div>
</template>
<script>
import ManageForm9 from "@/components/ManageForm9/ManageForm9";
export default {
  components: {
    ManageForm9,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>